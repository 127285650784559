<template>
  <div>
    <AppCollapse
      type="border-warning-2 card"
      class="mb-50"
    >
      <AppCollapseItem
        is-visible
        title
        class-header="card-header bg-blue-gradient p-0 m-50"
        class="border-warning-2"
      >
        <template #header>
          <div class="d-flex-center justify-content-start gap-1">
            <IAmIcon
              icon="trainIcon"
              size="40"
            />
            <div class="my-25 mr-3">
              <div class="d-flex mr-25">
                <div
                  :class="`text-airline mr-50 fw-700 my-auto
                  ${isMobileView ? 'font-small-4' : 'font-medium-3'}
                `"
                >
                  {{ $t('train.search.result.ticketCartInfo') }}
                </div>

                <b-button
                  variant="outline-warning"
                  class="px-25 px-md-50"
                  size="sm"
                  @click.stop="handleOpenModalSetServiceFee"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="12"
                    class="mr-50"
                  />
                  <span>{{ $t('flight.setServiceFee') }}</span>
                </b-button>
              </div>
              <span>
                {{ $t('train.search.result.fillCompleteInfo') }} <span class="fw-700">{{ $t('train.search.result.fillCompleteInfo2') }}</span> {{ $t('train.search.result.or') }} <span class="fw-700">{{ $t('train.search.result.fillCompleteInfo3') }}</span>
              </span>
            </div>
          </div>
        </template>

        <!-- ANCHOR - BODY: THÔNG TIN HÀNH KHÁCH -->
        <div v-if="isEmpty(stateTicketSelectedArray[itineraryIndex])">
          <b-alert
            variant="warning"
            show
            class="p-1"
          >
            {{ $t('train.search.result.noTicketSelected') }}
          </b-alert>
        </div>

        <div v-else>
          <IAmOverlay
            :loading="loadingAppliesPromotions"
            :spinner-variant="'primary'"
          >
            <ValidationObserver ref="formPassengerData">
              <template v-for="(ticket, indexTicket) of stateTicketSelectedArray[itineraryIndex]">
                <CardPaxDataItem
                  :key="ticket.seatId"
                  :ticket="ticket"
                  :indexTicket="indexTicket"
                  :itineraryIndex="itineraryIndex"
                  :tablePaxPriceColumns="tablePaxPriceColumns"
                  @changePaxTypeCode="handleChangeTypePax"
                  @updateDataPax="updateDataPax"
                />
              </template>
            </ValidationObserver>
          </IAmOverlay>
        </div>
      </AppCollapseItem>
    </AppCollapse>

    <!-- TODO: show modal > check dob > hợp lệ > emit dob -->
    <ModalCheckBirthDayPax
      :itineraryIndex="itineraryIndex"
      :paxType="paxTypeCheckDoB"
      :ticketIdVal="ticketIdVal"
      :paxTargetCode="paxTargetCode"
      @handleUpdate="updateTypeData"
    />
    <ModalCustomServiceFee :id="itineraryIndex" />
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import { ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import store from '@/store'
import IAmOverlay from '@/components/IAmOverlay.vue'

import {
  convertISODateTimeLangVN,
  convertISODateTime,
  formatCurrency,
} from '@core/utils/filter'
import { formatterNameOnly, removeAccentsUpperCaseFormatterNotTrim, formatterPassportOnly } from '@core/comp-functions/forms/formatter-input'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BAlert,
    BButton,

    IAmOverlay,
    ValidationObserver,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    ModalCheckBirthDayPax: () => import('./ModalCheckBirthDayPax.vue'),
    CardPaxDataItem: () => import('./CardPaxDataItem.vue'),
    ModalCustomServiceFee: () => import('./ModalCustomServiceFee.vue'),
  },
  props: {
    itineraryIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      loadingAppliesPromotions,
      stateTicketSelectedArray,
      getListTrainStation,
      getListPassengerTarget,
      delay,
      resolveNameByListSeatType,
      deleteTicketHandle,
      getAirportName,
      resolveDiscountPriceByPassengerCode,
      resolveTotalPriceTicket,
    } = useTrainHandle()

    const tablePaxPriceColumns = ['seatInfo', 'ticketPrice', 'discountPromotion', 'totalPrice']

    function updateDataPax({ field, ticketId, value }) {
      const data = {
        storeTicketIndex: props.itineraryIndex,
        ticketId,
        field,
        value,
      }
      store.commit('app-train/UPDATE_DATA_PAX', data)

      if (['fullName', 'identifyNumber'].includes(field)) {
        store.commit('app-train/TOGGLE_IS_UPDATE_DATA_PAX')
      }

      // NOTE update data của vé REF
      const storeTicket = stateTicketSelectedArray.value[props.itineraryIndex]?.find(tk => tk.ticketId === ticketId)
      if (storeTicket?.refTicketId) {
        const refData = {
          ...data,
          storeTicketIndex: props.itineraryIndex ? 0 : 1,
          ticketId: storeTicket.refTicketId,
        }
        store.commit('app-train/UPDATE_DATA_PAX', refData)
      }

      if (field === 'passengerTargetCode') { // NOTE: đổi paxTypeCode => call lại promotion cho vé đó và vé ref
        const listTicketId = [ticketId]
        if (storeTicket?.refTicketId) listTicketId.push(storeTicket.refTicketId)
        store.dispatch('app-train/appliesPromotions', { listTicketsIds: listTicketId })
      }
    }

    // FIXME: update data vào store với ticketVal, update passengerTargetCode thì call promotion
    function updateTypeData(data) {
      const { ticketId, passengerTargetCode, birthday = '' } = data
      updateDataPax({ field: 'passengerTargetCode', ticketId, value: passengerTargetCode })
      updateDataPax({ field: 'identifyNumber', ticketId, value: birthday })
    }

    // ANCHOR - Check Birthday
    const ticketIdVal = ref(null)
    const paxTargetCode = ref(0)
    const paxTypeCheckDoB = ref('CHILD')

    async function handleChangeTypePax({ ticketId, value }) {
      switch (value) {
        case 2:
          paxTypeCheckDoB.value = 'CHILD'
          ticketIdVal.value = ticketId
          paxTargetCode.value = value
          await delay(200)
          this.$bvModal.show(`modal-result-train-check-birthday-pax-${props.itineraryIndex}`)
          break

        case 7: { // Trẻ em [, nước ngoài]
          paxTypeCheckDoB.value = 'CHILD'
          ticketIdVal.value = ticketId
          paxTargetCode.value = value
          await delay(200)
          this.$bvModal.show(`modal-result-train-check-birthday-pax-${props.itineraryIndex}`)
          break
        }

        case 13: { // Người cao tuổi
          paxTypeCheckDoB.value = 'ELDER'
          ticketIdVal.value = ticketId
          paxTargetCode.value = value
          await delay(200)
          this.$bvModal.show(`modal-result-train-check-birthday-pax-${props.itineraryIndex}`)
          break
        }

        default: {
          updateTypeData({ ticketId, passengerTargetCode: value })
          break
        }
      }
    }

    function handleShowDataPromotion({ name, data }) {
      // console.log({ name, data })
      // this.$bvModal.show(`modal-result-train-detail-promotion-ticket-${props.itineraryIndex}`)
    }

    function handleOpenModalSetServiceFee() {
      this.$bvModal.show(`modal-train-customer-service-fee-${props.itineraryIndex}`)
    }

    return {
      loadingAppliesPromotions,
      tablePaxPriceColumns,
      paxTypeCheckDoB,
      isEmpty,
      formatCurrency,
      formatterNameOnly,
      formatterPassportOnly,
      removeAccentsUpperCaseFormatterNotTrim,
      stateTicketSelectedArray,
      getListTrainStation,
      getListPassengerTarget,
      deleteTicketHandle,
      handleShowDataPromotion,
      resolveDiscountPriceByPassengerCode,
      getAirportName,
      convertISODateTimeLangVN,
      convertISODateTime,
      resolveNameByListSeatType,
      handleChangeTypePax,

      updateDataPax,
      updateTypeData,
      ticketIdVal,
      paxTargetCode,

      resolveTotalPriceTicket,
      handleOpenModalSetServiceFee,
    }
  },
}
</script>
