var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-warning-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-warning-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "trainIcon",
            "size": "40"
          }
        }), _c('div', {
          staticClass: "my-25 mr-3"
        }, [_c('div', {
          staticClass: "d-flex mr-25"
        }, [_c('div', {
          class: "text-airline mr-50 fw-700 my-auto\n                ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-3', "\n              ")
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.ticketCartInfo')) + " ")]), _c('b-button', {
          staticClass: "px-25 px-md-50",
          attrs: {
            "variant": "outline-warning",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.handleOpenModalSetServiceFee($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EditIcon",
            "size": "12"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('flight.setServiceFee')))])], 1)], 1), _c('span', [_vm._v(" " + _vm._s(_vm.$t('train.search.result.fillCompleteInfo')) + " "), _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(_vm.$t('train.search.result.fillCompleteInfo2')))]), _vm._v(" " + _vm._s(_vm.$t('train.search.result.or')) + " "), _c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(_vm.$t('train.search.result.fillCompleteInfo3')))])])])], 1)];
      },
      proxy: true
    }])
  }, [_vm.isEmpty(_vm.stateTicketSelectedArray[_vm.itineraryIndex]) ? _c('div', [_c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.noTicketSelected')) + " ")])], 1) : _c('div', [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingAppliesPromotions,
      "spinner-variant": 'primary'
    }
  }, [_c('ValidationObserver', {
    ref: "formPassengerData"
  }, [_vm._l(_vm.stateTicketSelectedArray[_vm.itineraryIndex], function (ticket, indexTicket) {
    return [_c('CardPaxDataItem', {
      key: ticket.seatId,
      attrs: {
        "ticket": ticket,
        "indexTicket": indexTicket,
        "itineraryIndex": _vm.itineraryIndex,
        "tablePaxPriceColumns": _vm.tablePaxPriceColumns
      },
      on: {
        "changePaxTypeCode": _vm.handleChangeTypePax,
        "updateDataPax": _vm.updateDataPax
      }
    })];
  })], 2)], 1)], 1)])], 1), _c('ModalCheckBirthDayPax', {
    attrs: {
      "itineraryIndex": _vm.itineraryIndex,
      "paxType": _vm.paxTypeCheckDoB,
      "ticketIdVal": _vm.ticketIdVal,
      "paxTargetCode": _vm.paxTargetCode
    },
    on: {
      "handleUpdate": _vm.updateTypeData
    }
  }), _c('ModalCustomServiceFee', {
    attrs: {
      "id": _vm.itineraryIndex
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }